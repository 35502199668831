import { useState } from "react";
import logo from "../assets/logo.png";

export default function Navbar() {
	const [isOpen, setIsOpen] = useState(false);

	const navItems = [
		{ label: "Features", id: "features" },
		{ label: "Sports", id: "sports" },
		{ label: "Screenshots", id: "screenshots" },
	];

	const scrollToSection = (sectionId) => {
		const element = document.getElementById(sectionId);
		if (element) {
			const offset = 80; // Adjust this value based on your navbar height
			const elementPosition = element.getBoundingClientRect().top;
			const offsetPosition = elementPosition + window.pageYOffset - offset;

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth",
			});
			setIsOpen(false);
		}
	};

	return (
		<nav className="fixed w-full bg-white/80 backdrop-blur-md z-50 shadow-sm">
			<div className="container mx-auto px-4">
				<div className="flex items-center justify-between h-16">
					<div className="flex-shrink-0">
						<a href="#" className="flex items-center space-x-2">
							<img src={logo} alt="Racketly" className="h-8 w-8" />
							<span className="font-bold text-xl">Racketly</span>
						</a>
					</div>

					{/* Desktop Navigation */}
					<div className="hidden md:flex items-center space-x-8">
						{navItems.map((item) => (
							<button
								key={item.id}
								onClick={() => scrollToSection(item.id)}
								className="text-gray-600 hover:text-gray-900 transition"
							>
								{item.label}
							</button>
						))}
					</div>

					{/* Mobile menu button */}
					<div className="md:hidden">
						<button
							onClick={() => setIsOpen(!isOpen)}
							className="text-gray-600 hover:text-gray-900 focus:outline-none"
						>
							<svg
								className="h-6 w-6"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								{isOpen ? (
									<path d="M6 18L18 6M6 6l12 12" />
								) : (
									<path d="M4 6h16M4 12h16M4 18h16" />
								)}
							</svg>
						</button>
					</div>
				</div>

				{/* Mobile Navigation */}
				{isOpen && (
					<div className="md:hidden">
						<div className="px-2 pt-2 pb-3 space-y-1">
							{navItems.map((item) => (
								<button
									key={item.id}
									onClick={() => scrollToSection(item.id)}
									className="block w-full text-left px-3 py-2 text-gray-600 
										 hover:text-gray-900 hover:bg-gray-50 rounded-md transition"
								>
									{item.label}
								</button>
							))}
						</div>
					</div>
				)}
			</div>
		</nav>
	);
}
