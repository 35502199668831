const sports = [
	{
		title: "Tennis",
		description:
			"Track your tennis matches with comprehensive scoring and statistics.",
		highlight: "Professional Scoring",
	},
	{
		title: "Padel",
		description:
			"Keep score of your padel games with specialized rules and point tracking.",
		highlight: "Specialized Rules",
	},
	{
		title: "Badminton",
		description:
			"Monitor your badminton matches with detailed point-by-point tracking.",
		highlight: "Point Tracking",
	},
	{
		title: "Pickleball",
		description:
			"Track your pickleball games with dedicated scoring and statistics.",
		highlight: "Custom Scoring",
	},
];

const SportsSection = () => {
	return (
		<section
			id="sports"
			className="bg-gradient-to-b from-white to-gray-50 py-24 overflow-hidden"
		>
			<div className="container mx-auto px-4 md:px-6">
				<div className="text-center mb-20" data-aos="fade-up">
					<h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
						Supported Sports
					</h2>
					<p className="text-gray-600 text-lg max-w-2xl mx-auto">
						Track your performance across multiple racket sports
					</p>
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-20 pb-4">
					{sports.map((sport, index) => (
						<div
							key={index}
							className="relative group"
							data-aos="fade-up"
							data-aos-delay={index * 100}
						>
							<div
								className="absolute -inset-0.5 bg-gradient-to-r from-blue-600 to-purple-600 rounded-2xl opacity-5 
								group-hover:opacity-10 transition duration-300"
								style={{ zIndex: 0 }}
							></div>

							<div className="relative bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition duration-300 h-full border border-gray-100">
								<span className="text-blue-600 font-medium text-sm uppercase tracking-wider mb-2 block">
									{sport.highlight}
								</span>

								<h3 className="text-2xl font-bold text-gray-900 mb-4">
									{sport.title}
								</h3>

								<p className="text-gray-600 leading-relaxed">
									{sport.description}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default SportsSection;
