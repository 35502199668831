import feature1 from "../../../assets/feature1.png";
import feature2 from "../../../assets/feature2.png";
import feature3 from "../../../assets/feature3.png";
import feature4 from "../../../assets/feature4.png";
import feature5 from "../../../assets/feature5.png";

const screenshots = [
	{
		image: feature1,
		title: "Track Your Matches",
		description:
			"Keep detailed records of all your games with comprehensive statistics and performance metrics. Analyze your progress over time.",
		highlight: "Real-time tracking and analysis",
	},
	{
		image: feature3,
		title: "Real-Time Scoring",
		description:
			"Update scores instantly from your Apple Watch or iPhone. Never miss a point with our seamless scoring system.",
		highlight: "Instant updates across devices",
	},
	{
		image: feature2,
		title: "Multi-Sport Support",
		description:
			"Support for multiple sports, including tennis, padel, and more.",
		highlight: "Versatile and customizable",
	},
	{
		image: feature4,
		title: "Play with Friends",
		description: "Play with friends and track your progress together.",
		highlight: "Social and competitive",
	},
	{
		image: feature5,
		title: "Customizable Stats",
		description:
			"Tailor your stats to focus on the metrics that matter most to you.",
		highlight: "Personalized tracking",
	},
];

const ScreenshotsSection = () => {
	return (
		<section
			id="screenshots"
			className="bg-gradient-to-b from-gray-900 to-black text-white py-24 overflow-hidden"
		>
			<div className="container mx-auto px-4 md:px-6">
				<div className="text-center mb-16" data-aos="fade-right">
					<h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
						Experience the Difference
					</h2>
					<p className="text-gray-400 text-lg max-w-2xl mx-auto">
						Discover how our app transforms your game tracking experience
					</p>
				</div>

				<div className="space-y-32">
					{screenshots.map((screenshot, index) => (
						<div
							key={index}
							className={`flex flex-col ${
								index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
							} items-center gap-12 md:gap-20`}
							data-aos="fade-right"
						>
							{/* Image container with floating effect */}
							<div className="w-full md:w-1/2 flex justify-center">
								<div className="relative transition-transform duration-300 hover:-translate-y-2">
									{/* Glow effect behind the phone */}
									<div className="absolute inset-0 bg-blue-500/20 blur-3xl rounded-full"></div>
									{/* Phone frame */}
									<img
										src={screenshot.image}
										alt={screenshot.title}
										className="relative w-[300px] h-[600px] object-cover rounded-[1rem] shadow-2xl shadow-blue-500/20"
									/>
								</div>
							</div>

							{/* Text content */}
							<div className="w-full md:w-1/2 text-center md:text-left space-y-6">
								<span className="text-blue-400 font-medium text-sm uppercase tracking-wider">
									{screenshot.highlight}
								</span>
								<h3 className="text-3xl md:text-4xl font-bold text-white">
									{screenshot.title}
								</h3>
								<p className="text-gray-400 text-lg leading-relaxed max-w-xl">
									{screenshot.description}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default ScreenshotsSection;
