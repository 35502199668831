import { APP_STORE_URL } from "../../constants/urls";

export default function CTASection() {
	return (
		<section className="py-20 bg-white">
			<div className="container mx-auto px-4 text-center">
				<h2 className="text-4xl font-bold mb-4 text-gray-900">
					Ready to Elevate Your Game?
				</h2>
				<p className="text-gray-600 mb-8 max-w-2xl mx-auto">
					Join thousands of players who trust Racketly to keep their scores
				</p>
				<div className="flex flex-col sm:flex-row justify-center gap-4">
					<a
						href={APP_STORE_URL}
						target="_blank"
						rel="noopener noreferrer"
						className="inline-flex items-center justify-center bg-gradient-to-r from-blue-600 to-purple-600 text-white px-8 py-3 rounded-lg hover:opacity-90 transition"
					>
						<svg
							className="w-6 h-6 mr-2"
							viewBox="0 0 24 24"
							fill="currentColor"
						>
							<path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z" />
						</svg>
						Download on App Store
					</a>
				</div>
			</div>
		</section>
	);
}
