export default function FooterSection() {
	return (
		<footer className="bg-black text-gray-400">
			<div className="container mx-auto px-4 py-16">
				<div className="grid grid-cols-2 md:grid-cols-4 gap-12">
					<div className="space-y-4">
						<h3 className="text-lg font-semibold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
							Product
						</h3>
						<ul className="space-y-3">
							<li className="hover:translate-x-1 transition-transform duration-200">
								<a
									href="#features"
									className="hover:text-white transition-colors duration-200 flex items-center gap-2"
								>
									<span className="h-px w-4 bg-blue-500/50"></span>
									Features
								</a>
							</li>
							<li className="hover:translate-x-1 transition-transform duration-200">
								<a
									href="#pricing"
									className="hover:text-white transition-colors duration-200 flex items-center gap-2"
								>
									<span className="h-px w-4 bg-blue-500/50"></span>
									Pricing
								</a>
							</li>
							<li className="hover:translate-x-1 transition-transform duration-200">
								<a
									href="#screenshots"
									className="hover:text-white transition-colors duration-200 flex items-center gap-2"
								>
									<span className="h-px w-4 bg-blue-500/50"></span>
									Screenshots
								</a>
							</li>
						</ul>
					</div>

					<div className="space-y-4">
						<h3 className="text-lg font-semibold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
							Connect
						</h3>
						<ul className="space-y-3">
							<li className="hover:translate-x-1 transition-transform duration-200">
								<a
									href="https://twitter.com/laprea_marcelo"
									target="_blank"
									rel="noopener noreferrer"
									className="hover:text-white transition-colors duration-200 flex items-center gap-2"
								>
									<span className="h-px w-4 bg-blue-500/50"></span>
									Twitter
								</a>
							</li>
							<li className="hover:translate-x-1 transition-transform duration-200">
								<a
									href="https://www.instagram.com/racketlyapp/"
									target="_blank"
									rel="noopener noreferrer"
									className="hover:text-white transition-colors duration-200 flex items-center gap-2"
								>
									<span className="h-px w-4 bg-blue-500/50"></span>
									Instagram
								</a>
							</li>
							<li className="hover:translate-x-1 transition-transform duration-200">
								<a
									href="https://www.tiktok.com/@racketly?_t=8rwhD00mByj&_r=1"
									target="_blank"
									rel="noopener noreferrer"
									className="hover:text-white transition-colors duration-200 flex items-center gap-2"
								>
									<span className="h-px w-4 bg-blue-500/50"></span>
									TikTok
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div className="border-t border-gray-800/50 mt-16 pt-8 flex flex-col md:flex-row justify-between items-center">
					<p className="text-sm text-gray-500">
						© {new Date().getFullYear()} Racketly. All rights reserved.
					</p>
					<div className="flex space-x-8 mt-4 md:mt-0">
						<a
							href="https://amplified-peach-f69.notion.site/Racketly-Privacy-Policy-And-Terms-and-Conditions-11596e38c99e8050a8c3da9513981ae8?pvs=4"
							className="text-sm text-gray-500 hover:text-white transition-colors duration-200 hover:translate-x-1 transition-transform"
						>
							Privacy Policy
						</a>
						<a
							href="https://amplified-peach-f69.notion.site/Racketly-Privacy-Policy-And-Terms-and-Conditions-11596e38c99e8050a8c3da9513981ae8?pvs=4"
							className="text-sm text-gray-500 hover:text-white transition-colors duration-200 hover:translate-x-1 transition-transform"
						>
							Terms of Service
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
}
